.contact {
    padding-top: 45px;
    padding-bottom: 45px;
    &-os {
        padding-top: 45px;
        padding-bottom: 45px;
        border-top: 1px solid #10415a;
        border-bottom: 1px solid #10415a;
        .contact__title {
            color: #fff;
        }
        &__container {
            background-color: rgba(8, 41, 90, 1);
            padding: 35px;
            label {
                float: left;
                color: #fff;
                font-size: 16px;
                font-weight: 500;
                cursor: pointer;
            }
            input {
                float: right;
            }
            textarea {
                float: right;
                resize: vertical;
                height: 150px;
                resize: none;
            }
        }
        .input__container {
            width: 100%;
            white-space: nowrap;
        }
    }
    &__input {
        // border: 1px solid #c0cad5;
        border: none;
        border-radius: 1px;
        box-shadow: 0 1px 0 #fff;
        color: #10415a;
        font-size: 16px;
        padding: 8px 10px;
        max-width: 355px;
        margin: 0 0 15px;
        width: 100%;
        outline: none;
        transition: all 0.3s;
        &:hover {
            // border: 1px solid #10415a;
        }
        &:focus {
            // border: 1px solid #10415a;
        }
    }
    &__title {
        font-size: 30px;
        font-weight: 500;
        text-transform: uppercase;
        letter-spacing: 1px;
        padding-bottom: 20px;
    }
    .list {
        padding-left: 30px;
        li {
            list-style: none;
            font-size: 18px;
            padding-top: 5px;
            padding-bottom: 5px;
            position: relative;
            &:before {
                content: '';
                position: absolute;
                left: -30px;
                top: 6px;
                width: 24px;
                height: 24px;
                background-position: center;
                background-repeat: no-repeat;
            }
        }
        li.address {
            &:before {
                background-image: url('../../volna/img/icon/location.png');
            }
        }
        li.phone {
            &:before {
                background-image: url('../../volna/img/icon/old-phone.png');
            }
        }
        li.mail {
            &:before {
                background-image: url('../../volna/img/icon/mail.png');
            }
        }
        a {
            color: #000;
        }
    }
}

.contact {
    &-os {
        .input-file {
            margin-bottom: 45px;
            float: right;
            position: relative;
            overflow: hidden;
            background-color: #fff;
            color: #757575;
            text-align: left;
            font-weight: 400;
            input {
                position: absolute;
                left: -9999px;
                width: 0;
                height: 0;
                overflow: hidden;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .contact {
        &-os {
            .contact__input {
                float: none;
                display: inline-block;
            }
            label {
                float: none;
                display: inline-block;
            }
            &__container {
                text-align: center;
            }
        }
    }
}

