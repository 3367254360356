header {
    padding: 40px 0;
    font-size: 0;
    .logo {
        text-align: center;
        display: inline-block;
        &__text {
            color: #1e4d93;
            font-size: 12px;
            padding-top: 10px;
            display: inline-block;
            width: 100%;
        }
    }
    .top__col {
        display: inline-block;
        vertical-align: middle;
        width: 25%;
        .call__container {
            text-align: right;
            .button {
                &__container {
                    width: 100%;
                    text-align: right;
                    &:nth-child(2) {
                        margin-top: 8px;
                    }
                }
            }
        }
        .list {
            margin-bottom: 0;
        }
        .link {
            display: block;
        }
        &:first-child {
            width: 30%;
            min-height: 70px;
        }
        &:nth-child(2) {
            width: 20%;
            min-height: 70px;
        }
        &:nth-child(3) {
            width: 30%;
            text-align: right;
            padding-right: 20px;
            min-height: 70px;
        }
        &:nth-child(4) {
            width: 20%;
            min-height: 70px;
        }
        &-text {
            color: rgb(30, 77, 147);
            font-family: Helvetica, Arial, sans-serif;
            font-size: 11px;
            font-style: normal;
            font-weight: 400;
            letter-spacing: 0px;
            text-decoration: none;
            vertical-align: top;
            line-height: 1.3;
        }
    }
    .list {
        &__item {
            color: #1e4d93;
            font-size: 12px;
            list-style: none;
            position: relative;
            padding: 1px 0;
            &:before {
                content: '';
                position: absolute;
                left: -23px;
                top: 1px;
                width: 16px;
                height: 16px;
                background-repeat: no-repeat;
                background-position: center;
            }
            &:first-child {
                &:before {
                    background-image: url("../img/icon/delivery1.png");
                }
            }
            &:nth-child(2) {
                &:before {
                    background-image: url("../img/icon/tag.png");
                }
            }
            &:nth-child(3) {
                &:before {
                    background-image: url("../img/icon/growth.png");
                }
            }
            &:nth-child(4) {
                &:before {
                    background-image: url("../img/icon/box.png");
                }
            }
        }
    }
}

@media screen and (max-width: 992px) {
    header {
        .top__col {
            &:first-child {
                width: 50%;
                .logo {
                    text-align: left;
                }
            }
            &:nth-child(2) {
                width: 50%;
            }
            &:nth-child(3) {
                width: 50%;
                margin-top: 20px;
                .link,
                .top__col-text {
                    text-align: left;
                }
            }
            &:last-child {
                width: 50%;
                margin-top: 20px;
                padding-left: 18px;
                .button__container {
                    text-align: left;
                }
            }
        }
        .link--phone {
            &:before {
                top: 20px;
                left: -55px;
                transform: rotate(85deg);
            }
        }
    }
}

@media screen and (max-width: 768px) {
    header {
        .top__col {
            &:first-child {
                width: 100%;
                text-align: center;
                .logo {
                    text-align: center;
                }
            }
            &:nth-child(2) {
                width: 100%;
                text-align: center;
                .list {
                    margin-top: 15px;
                }
            }
            &:nth-child(3) {
                width: 100%;
                padding-right: 0;
                text-align: center;
                .link--mail,
                .link,
                .top__col-text {
                    text-align: center;
                }
            }
            &:last-child {
                padding-left: 0;
                width: 100%;
                .button__container {
                    text-align: center;
                }
            }
        }
        .list {
            padding-left: 0;
            &__item {
                &:before {
                    content: none;
                }
            }
        }
    }
}

@media screen and (max-width: 370px) {
    header {
        .link--phone {
            &:before {
                content: none;
            }
        }
    }
}