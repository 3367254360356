.modal-header {
    border-bottom: none;
}

.modal-footer {
    border-top: none;
}

.modal-body {
    textarea {
        resize: vertical;
        min-height: 100px;
    }
}

.modal-title {
    color: #10415a;
    font-size: 20px;
    margin: 0 0 16px;
    padding: 0;
    text-align: center;
    padding-top: 20px;
}

#systemModal {
    .name-modal {
        color: #10415a;
        font-size: 20px;
        padding: 15px;
        padding-bottom: 25px;
        text-align: center;
    }
}