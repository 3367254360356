@import "./base/text";
@import "./base/top";
@import "./base/main";
@import "./base/gallery";
@import "./base/contact";
@import "./base/nav";
@import "./base/footer";
@import "./base/button";
@import "./base/input";
@import "./base/table";
@import "./base/modal";
body {
    background: #fff;
    font-family: 'Roboto', sans-serif;
}