.table {
    margin-bottom: 50px;
    &-content-price {
        overflow-x: hidden;
    }
}

.table>tbody>tr>td,
.table>tbody>tr>th,
.table>tfoot>tr>td,
.table>tfoot>tr>th,
.table>thead>tr>td,
.table>thead>tr>th {
    border-right: 1px solid #fff;
    line-height: 1.42857;
    padding: 8px;
    vertical-align: middle;
    border-top: 0;
}

th {
    background: #333132 none repeat scroll 0 0;
    color: #fff;
    font-weight: 200;
}

tr:hover {
    background: #ececec;
}

td {
    img {
        max-height: 130px;
    }
}

@media screen and (max-width: 992px) {
    .table {
        &-content-price {
            overflow-x: scroll;
        }
    }
}