.navbar {
    background: #232323;
    border-radius: 0;
    margin-bottom: 0;
    border: none;
    &-collapse {
        text-align: center;
        font-size: 0;
    }
    &-nav {
        float: none;
        display: inline-block;
    }
}

.navbar.fixed {
    position: fixed;
    top: 0;
    width: 100%;
}

.icon-bar {
    background: #fff;
}

.nav {
    li {
        a {
            color: #fff;
            font-size: 14px;
            font-weight: 600;
            &:after {
                content: '';
                position: absolute;
                bottom: -4px;
                left: 0;
                width: 100%;
                height: 0px;
                transition: all 0.3s ease-in;
            }
            &:focus,
            &:hover {
                background: #232323;
                position: relative;
                &:after {
                    transition: height 0.3s ease-in;
                    height: 8px;
                    background: rgb(255, 93, 85);
                }
            }
        }
        a.active {
            background: #232323;
            border-bottom: 4px solid rgb(255, 93, 85);
            transition: all 0;
            &:hover {
                transition: all 0.3s;
                border-bottom: 4px solid transparent;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .navbar-nav {
        width: 100%;
    }
}