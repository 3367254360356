.md-20 {
    font-size: 20px!important;
}

.link {
    color: #1e4d93;
    transition: all 0.3s;
    &:hover {
        transition: all 0.3s;
    }
    &--phone {
        position: relative;
        font-weight: 600;
        font-size: 26px;
        transition: all 0.5s;
        color: #1E4D94;
        display: inline-block;
        span {
            font-weight: 400;
        }
    }
    &--mail {
        text-align: right;
        color: rgb(30, 77, 147);
        font-family: Helvetica, Arial, sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        letter-spacing: 0px;
        vertical-align: middle;
        line-height: 1.3;
    }
}

.top_page {
    min-height: 270px;
    background-repeat: no-repeat;
    background-size: cover;
    &__container {
        background-color: rgba(8, 41, 90, .75);
        min-height: 270px;
        height: 100%;
        padding-bottom: 20px;
        .list {
            margin-bottom: 70px;
        }
        .button__container {
            text-align: right;
            margin-bottom: 40px;
            margin-top: 20px;
            position: absolute;
            bottom: 0;
            right: 0;
            .button {
                margin: 50px;
                padding: 8px 9px;
                border: none;
            }
        }
        .text {
            color: #fff;
            padding: 25px 35px 15px 25px;
        }
        .list {
            &--one {
                position: absolute;
                top: 50%;
                left: 40%;
                transform: translate(-50%, -50%);
            }
            li {
                font-size: 18px;
                color: #fff;
                list-style: none;
            }
            a {
                font-size: 16px;
                color: #fff;
            }
        }
    }
}

.main_bg_block {
    min-height: 700px;
    background: url(../img/items_bg.jpg) no-repeat;
    overflow: hidden;
    background-position: top;
    background-size: cover;
    .col-custom {
        padding-left: 4px;
        padding-right: 4px;
        &:last-child {
            margin-bottom: 25px;
        }
    }
    a {
        &:hover {
            text-decoration: none;
            .item_bg_background {
                z-index: 1;
            }
            .item_bg_container {
                z-index: 2;
                background-color: rgba(8, 41, 90, 0);
            }
        }
    }
}

.item_bg {
    margin-bottom: 4px;
    margin-top: 4px;
    cursor: pointer;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    background-image: none;
    background-color: transparent;
    &_background {
        position: absolute;
        left: 4px;
        top: 4px;
        width: calc(100% - 8px);
        height: calc(100% - 8px);
        z-index: -1;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        filter: grayscale(100%);
    }
    &_container {
        position: relative;
        padding: 31px 45px 8px;
        background-color: rgba(8, 41, 90, 0.7);
        transition: background-color 0.3s;
        text-align: center;
        &:hover {
            background-color: transparent;
        }
    }
    .item_img {
        display: inline-block;
        height: 64px;
        line-height: 58px;
        overflow: hidden;
        filter: brightness(0) invert(1);
        img {
            max-width: 100%;
            max-height: 100%;
            filter: brightness(0) invert(1);
        }
    }
    .hr_half {
        height: 4px;
        background: rgb(255, 93, 85);
        margin: 16px 0 12px;
        margin-left: 33.33333%;
        width: 33.33333%;
    }
    .item_title {
        color: #fff;
        font-size: 14px;
        font-weight: 600;
        min-height: 83px;
        line-height: 1.4;
        margin: 15px 0 0;
        overflow: hidden;
    }
}

.block__text {
    padding-top: 25px;
    padding-bottom: 25px;
}

.block__text.gray {
    background-color: #ececec;
}

.order-make {
    padding-top: 45px;
    padding-bottom: 35px;
    &__container {
        font-size: 0;
        border: 2px solid #08295a;
        background: linear-gradient(90deg, rgb(8, 41, 90) 50%, rgb(255, 255, 255) 50%);
    }
    &__item {
        margin-left: 40px;
        position: relative;
        padding-bottom: 10px;
        cursor: pointer;
        display: inline-block;
        .text {
            margin-bottom: 0;
        }
        &:before {
            content: '';
            position: absolute;
            width: 18px;
            height: 24px;
            top: 0;
            left: -30px;
            background-repeat: no-repeat;
            background-position: center;
        }
    }
    &__item.online {
        &:before {
            background-image: url("../img/icon/support-online.png");
        }
    }
    &__item.bill {
        &:before {
            background-image: url("../img/icon/invoice.png");
        }
    }
    &__item.call {
        &:before {
            width: 24px;
            background-image: url("../img/icon/call.png");
        }
    }
    .col_1 {
        width: 50%;
        display: inline-block;
        vertical-align: middle;
        padding: 25px 55px 35px 35px;
        .head__title {
            text-transform: none;
            color: #fff;
        }
        .text {
            color: #fff;
        }
    }
    .col_2 {
        width: 50%;
        display: inline-block;
        vertical-align: middle;
        padding: 25px 55px 35px 35px;
        background: #fff;
    }
}

@media screen and (max-width: 768px) {
    .top_page {
        overflow: hidden;
    }
    .order-make {
        &__container {
            border: 2px solid #08295a;
            background: #08295a;
        }
        .col_1 {
            width: 100%;
        }
        .col_2 {
            width: 100%;
        }
    }
    .top_page {
        .container {
            padding-right: 0;
            padding-left: 0;
        }
    }
}