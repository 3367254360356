footer {
    padding-top: 50px;
    padding-bottom: 20px;
    background-color: #232323;
    .logo {
        float: right;
        margin-bottom: 10px;
    }
    .text {
        color: #fff;
        float: right;
        margin-bottom: 5px;
    }
    .list {
        padding-left: 0;
        &__title {
            color: #fff;
            font-size: 16px;
            padding-bottom: 15px;
        }
        li {
            list-style: none;
            padding-bottom: 3px;
        }
        a {
            color: #fff;
        }
    }
    .link--phone {
        color: #fff;
        float: right;
        &:before {
            content: none;
        }
    }
    .copyright {
        color: #fff;
        font-size: 16px;
        display: inline-block;
        padding-top: 20px;
    }
    .studio {
        float: right;
    }
}

@media screen and (max-width: 768px) {
    footer {
        .logo {
            float: none;
            text-align: center;
            width: 100%;
            display: inline-block;
        }
        .text {
            float: none;
            text-align: center;
            width: 100%;
        }
        .link--phone {
            float: none;
            text-align: center;
            width: 100%;
        }
    }
}