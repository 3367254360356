.input {
    border: 1px solid #c0cad5;
    border-radius: 1px;
    box-shadow: 0 1px 0 #fff;
    color: #10415a;
    font-size: 16px;
    padding: 8px 10px;
    width: 100%;
    &__container {
        width: 255px;
        margin: 15px auto;
    }
}