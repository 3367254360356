.button {
    outline: none;
    background: #fff;
    border: 2px solid #1e4d93;
    color: #1e4d93;
    text-align: center;
    font-size: 15px;
    font-weight: 600;
    transition: all 0.3s;
    padding: 10px 15px;
    &:hover {
        color: #fff;
        background: #1e4d93;
        transition: all 0.3s;
    }
    i {
        position: relative;
        top: 7px;
        padding-right: 5px;
        line-height: 0;
    }
    &--call-me,
    &--price {
        background: #fff;
        border: none;
        border-bottom: 4px solid #1e4d93;
        color: #1e4d93;
        font-size: 12px;
        padding: 5px 0px;
        transition: all 0.3s;
        &:hover {
            background: #fff;
            color: rgb(255, 93, 85);
            text-decoration: none;
            border-bottom: 4px solid rgb(255, 93, 85);
            transition: all 0.3s;
        }
    }
    &--price-download {
        width: 100%;
        background: transparent;
        outline: none;
        border: none;
        padding: 0;
        margin: 0;
    }
    &__container {
        margin: 0 auto;
        text-align: center;
    }
}