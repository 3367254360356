.head_2 {
    color: rgb(235, 224, 224);
    font-size: 20px;
}

.head_3 {
    color: #08295a;
    font-size: 18px;
}

.head__title {
    font-size: 30px;
    margin-bottom: 37px;
    text-transform: uppercase;
}

.text {
    line-height: 1.4;
    font-size: 16px;
}

.article__slider {
    float: right;
    margin: 0 0 30px 30px;
    width: 40%;
    @media screen and (max-width: 767px) {
        float: none;
        width: auto;
        margin: 0;
    }
}